import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const urlService = 'https://dev-api-dalefon.coru.com/graphql';

const httpLink = new HttpLink({
  uri: urlService,
  credentials: 'include',
  fetch,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    securityWord: 'test',
  },
}));

const client = () =>
  new ApolloClient({
    connectToDevTools: true,
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });

export default client;
